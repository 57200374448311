<template>
  <v-data-table
    id="employees-data-table"
    no-data-text="No data available"
    disable-pagination
    hide-default-footer
    fixed-header
    :headers="headers"
    :items="employeesFiltered"
    :dense="denseTables"
    :height="defaultTableHeight"
    :sort-by="['name']"
    @click:row="navigateToDetails"
  >
    <template #footer>
      <v-divider />
      <v-row class="table-footer">
        <span class="footer-text">Total records: {{ employeesFiltered.length }}</span>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { defaultTableHeight } from '@/constants/tableHeights';

export default {
  data: () => ({
    defaultTableHeight,
    headers: [
      {
        text: 'Name',
        value: 'name',
        class: ['projects-headers'],
      },
      {
        text: 'Email',
        value: 'email',
        class: ['projects-headers'],
      },
      {
        text: 'Office',
        value: 'office_name',
        class: ['projects-headers'],
        width: '120px',
      },
      {
        text: 'Position',
        value: 'position',
        class: ['projects-headers'],
      },
      {
        text: 'Role',
        value: 'employee_role',
        class: ['projects-headers'],
        width: '150px',
      },
      {
        text: 'Status',
        value: 'status',
        class: ['projects-headers'],
        width: '150px',
      },
    ],
  }),
  computed: {
    ...mapGetters('user/settings', ['denseTables']),
    ...mapGetters('employees/list', ['employeesFiltered']),
  },
  methods: {
    navigateToDetails({ id }) {
      this.$router.push({
        name: 'Employee Details',
        params: { id },
      });
    },
  },
};
</script>

<style lang="less">
@import "~variables";

  #employees-data-table .projects-headers {
    font-size: @table-font-size;
  }
</style>
