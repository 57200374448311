<template>
  <v-row class="filters-row-wrapper">
    <v-col>
      <v-text-field
        class="secondary-background"
        label="Name"
        hide-details
        outlined
        dense
        :value="filters.name"
        @input="setNameFilter"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        class="secondary-background"
        label="Office"
        item-text="name"
        hide-details
        outlined
        dense
        :items="userOffices"
        :value="userOffices.length === 1 ? [userOffices[0].id] : filters.officeIds"
        :disabled="user.role !== systemRolesNames.admin"
        @update="setOfficeFilter"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        class="secondary-background"
        label="Role"
        item-text="title"
        hide-details
        outlined
        dense
        :items="systemRoles"
        :value="filters.systemRoleIds"
        @update="setSystemRoleFilter"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        class="secondary-background"
        label="Status"
        item-text="title"
        return-object
        hide-details
        outlined
        dense
        :items="statusFilterOptions"
        :value="statusFilter"
        @update="setStatusFilter"
      />
    </v-col>
    <v-col class="analitic-btn">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <a href="/dashboard?targetName='HR'">
            <v-btn
              class="mr-4"
              icon
              v-on="{ ...tooltip }"
            >
              <AnalyticIcon>Some text</AnalyticIcon>
            </v-btn>
          </a>
        </template>
        <span>{{ BItext }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import AnalyticIcon from '@/assets/BI1.svg';
import { MultiChoiceFilter } from '@/components/shared';
import { systemRolesNames } from '@/constants/roles';

export default {
  components: {
    MultiChoiceFilter,
    AnalyticIcon,
  },
  data: () => ({
    systemRolesNames,
    BItext: 'Employees Analytics',
  }),
  computed: {
    ...mapState('employees/list', [
      'filters',
    ]),
    ...mapGetters('employees/list', [
      'statusFilter',
      'statusFilterOptions',
    ]),
    ...mapGetters('shared/offices', ['userOffices']),
    ...mapGetters('shared/roles', ['systemRoles']),
    ...mapGetters('auth/account', ['user']),
  },
  created() {
    this.getSystemRoles();
  },
  methods: {
    ...mapActions('shared/roles', ['getSystemRoles']),
    ...mapMutations('employees/list', [
      'setNameFilter',
      'setOfficeFilter',
      'setSystemRoleFilter',
      'setStatusFilter',
    ]),
  },
};
</script>

<style lang="less">
  @import "~@/styles/actions-bar";
  @import "~@/styles/analytic-btn";
</style>
