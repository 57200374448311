<template>
  <div>
    <v-row class="actions-bar pa-4 bar-shadow primary-background">
      <v-col>
        <EmployeesFilter />
      </v-col>
      <v-col
        v-if="isUserAdmin"
        class="actions"
      >
        <Button
          id="sync-all-button"
          color="primary"
          :action-names="[loadingTypes.syncAllButton]"
          @clickAction="onSyncAll"
        >
          {{ syncAllText }}
        </Button>
      </v-col>
    </v-row>

    <v-row class="pa-4">
      <v-col>
        <v-card>
          <EmployeesTable />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Button } from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import apiClient from '@/services/api-client';

import EmployeesFilter from './EmployeesFilters.vue';
import EmployeesTable from './EmployeesTable.vue';

export default {
  components: {
    Button,
    EmployeesFilter,
    EmployeesTable,
  },
  data() {
    return {
      isFiltersOpened: true,
      syncAllText: 'Sync All',
      loadingTypes,
    };
  },
  computed: {
    ...mapGetters('auth/account', [
      'user',
      'isUserAdmin',
    ]),
  },
  created() {
    this.getEmployees();
  },
  methods: {
    ...mapActions('employees/list', ['getEmployees']),

    async onSyncAll() {
      await apiClient.syncApi.syncAll();
      await this.getEmployees();
    },
  },
};
</script>
